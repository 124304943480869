import SectionHeading from "../../../components/SectionHeading";
import React, { useContext, useState } from "react";
import { apiUrl } from "../../../config";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LanguageContext } from "../../../languagesContext";
import { languages } from "../../../languages";
const SecContact = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const selectedLanguage = languages[language];
  const togglePasswordVisibility = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    } else {
      setPasswordType("password");
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error("Please provide email end password");
      return;
    }
    const body = {
      email,
      password,
    };
    const redirectToAnotherPage = (url, token) => {
      const href = `${url}/?token=${token}`;
      window.location.replace(href);
    };
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const endpoint = "/login";
    const raw = JSON.stringify(body);
    const url = apiUrl + endpoint;
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.token) {
          setLoading(false);
          redirectToAnotherPage(result.account_uri, result.token);
        } else {
          setLoading(false);
          if (result.error) {
            toast.error(result.error);
          } else if (result.data) {
            toast.error(result.data.message);
          } else {
            toast.error("Something went wrong");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error error error");
        toast.error(error.message);
      });
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <div className="contact_us_area" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionHeading title={selectedLanguage.All.SignIn} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="contact_form">
              <div className="row">
                <div className="col-12">
                  <div id="success_fail_info" />
                </div>
                <div className="col-12 col-md-12" data-aos="fade-up">
                  <div className="group wow fadeInUp" data-wow-delay="0.2s">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      required
                      onChange={handleEmailChange}
                    />
                    <span className="highlight" />
                    <span className="bar" />
                    <label>{selectedLanguage.All.Email}</label>
                  </div>
                </div>
                <div className="col-12 col-md-12" data-aos="fade-up">
                  <div
                    className="group wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{ position: "relative" }}
                  >
                    <input
                      type={passwordType}
                      name="password"
                      id="password"
                      required
                      onChange={handlePasswordChange}
                    />
                    <span className="highlight" />
                    <span className="bar" />
                    <label>{selectedLanguage.All.Password}</label>
                  </div>
                  <i
                    id="eyeIcon"
                    className="fa fa-eye"
                    style={{
                      color: "green",
                      position: "absolute",
                      top: "5px",
                      right: "25px",
                      zIndex: "100",
                      cursor: "pointer",
                    }}
                    onClick={togglePasswordVisibility}
                  ></i>
                </div>
                <div
                  className="col-12 text-center wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <button
                    type="submit"
                    className="btn dream-btn"
                    data-aos="fade-up"
                    onClick={handleClick}
                    disabled={loading}
                  >
                    {loading
                      ? selectedLanguage.All.Loading
                      : selectedLanguage.All.SignIn}
                  </button>
                  <h6 className=" py-4">{selectedLanguage.All.DontHaveAcc}</h6>
                  <Link to="/signup">
                    <div className=" " data-aos="fade-up">
                      {selectedLanguage.All.Register}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecContact;
